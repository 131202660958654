import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {ContactUsserviceFileupload} from "../services/contactUsserviceFileupload.service";
import {ContactUsservice} from "../services/contactUsservice.service";
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalStorageService } from 'angular-web-storage';




@Component({
  selector: 'contactUsAdd',
  templateUrl: './contactUsAdd.component.html',
  styleUrls: ['../app.component.css']
})
export class ContactUsAdd implements OnInit {
  

  dataForm: FormGroup;
  haserror = false;
  errors: string[];
      submittedDataForm = false;

  public Editor = ClassicEditor;
  public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  

  constructor(private fb: FormBuilder, 
    private contactUsserviceFileupload: ContactUsserviceFileupload,
    private contactUsservice: ContactUsservice,
    private location: Location,

    private router: Router,private local: LocalStorageService) { }

  ngOnInit() {

    this.dataForm = this.fb.group({
      name: [,Validators.required], email: [,Validators.required], phoneNo: [,Validators.required], productType: [,Validators.required], message: [,Validators.required], status: [1,Validators.required],
    });

  } 
  goBack(): void {
    this.location.back();
  }
   


  
  get validate() { return this.dataForm.controls; }

  onSubmit() {
    
   this.submittedDataForm = true;

    // stop here if form is invalid
    if (this.dataForm.invalid) {
        return;
    }
    const formData = new FormData();
				    let contactUs={"assetName":this.dataForm.get('name').value,"assetParentId":0,"assetPropertyList":[{"assetTypePropertyName":"name","assetPropertyValue":this.dataForm.get('name').value},{"assetTypePropertyName":"email","assetPropertyValue":this.dataForm.get('email').value},{"assetTypePropertyName":"phoneNo","assetPropertyValue":this.dataForm.get('phoneNo').value},{"assetTypePropertyName":"productType","assetPropertyValue":this.dataForm.get('productType').value},{"assetTypePropertyName":"message","assetPropertyValue":this.dataForm.get('message').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value}],"assetTypeId":this.local.get("contactUs")};  
				   
				    this.contactUsserviceFileupload.add(contactUs ,formData ).then(model =>{
    	                if (model!=undefined) {	
	    	                let response = JSON.parse(JSON.stringify(model));

					        if (response.status==true) {
						    		 this.router.navigate(['/contactUs']);
					          	
					        }
					    }
				    });
    

  }

}