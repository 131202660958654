import { InjectionToken } from "@angular/core";


export let APP_CONFIG = new InjectionToken("app.config");

export class AppConstants {
    
   
    public static get commonUrl(): string {return  'https://api.websoftiot.com/connect-everything/';}
    public static get connectUserUrl(): string {return  'https://api.websoftiot.com/connect-user/';}
    public static get projectId(): string {return '564710774';}//1979009798


    public static get languages(): Array<any> {return 
        [
            {'code':'en','lang':'CODE_EN','flag': 'flag-usa.png'},
            {'code':'es','lang':'CODE_ES','flag': 'flag-spain.png'},                                                                                    
            {'code':'ar','lang':'CODE_AR','flag': 'flag-united-arab-emirates.png'}
            /*{'code':'it','lang':'CODE_IT','flag': 'flag-italy.png'},
            {'code':'de','lang':'CODE_DE','flag': 'flag-germany.png'}*/
        ];


    };

}