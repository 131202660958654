
import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';  
import {Component, OnInit} from '@angular/core';
import {Bannerformrule} from './bannerformrule';  
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Bannerservice} from '../services/bannerservice.service';
import {BannerserviceFileupload} from "../services/bannerserviceFileupload.service";
import { LocalStorageService } from 'angular-web-storage';
import {Location} from "@angular/common";

import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'bannerUpdate',
  templateUrl: './bannerUpdate.component.html',
  styleUrls: ['../app.component.css']
})

export class BannerUpdate implements OnInit {
  dataForm: FormGroup;
  haserror = false;
  errors: string[];
  assetId: string;
  bannerformrule: Bannerformrule;
    public Editor = ClassicEditor;

     public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  constructor(private bannerservice: Bannerservice,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private bannerserviceFileupload: BannerserviceFileupload,
              private formBuilder: FormBuilder,
              private local: LocalStorageService) {
  };

  ngOnInit(): void {
    this.route.params
      .pipe(switchMap((params: Params) => this.bannerservice.get(params['id'])))
      .subscribe(bannerformrule => {
      let response = JSON.parse(JSON.stringify(bannerformrule));
        this.bannerformrule = response.response[0].assetProperties;
        this.assetId = response.response[0].assetId;
        this.buildForm();
      });
  }

  buildForm(): void {
    this.dataForm = this.formBuilder.group({
      bannerName: [this.bannerformrule.bannerName, Validators.required],bannerImage: [, Validators.required],imageAlt: [this.bannerformrule.imageAlt, Validators.required],showfor: [this.bannerformrule.showfor, Validators.required],orderBy: [this.bannerformrule.orderBy, Validators.required],status: [this.bannerformrule.status, Validators.required],
    });
  }

  


  onSelectedbannerImage(event) {
							    if (event.target.files.length> 0) {
							      const bannerImage = event.target.files[0];
							      this.dataForm.get('bannerImage').setValue(bannerImage);
							    }
							  }

  onUpdate() {
    const formData = new FormData();
				    let banner={"assetId":this.assetId,"assetName":this.dataForm.get('bannerName').value,"assetParentId":0,"assetPropertyList":[{"assetTypePropertyName":"bannerName","assetPropertyValue":this.dataForm.get('bannerName').value},{"assetTypePropertyName":"imageAlt","assetPropertyValue":this.dataForm.get('imageAlt').value},{"assetTypePropertyName":"showfor","assetPropertyValue":this.dataForm.get('showfor').value},{"assetTypePropertyName":"orderBy","assetPropertyValue":this.dataForm.get('orderBy').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value},],"assetTypeId":this.local.get("banner")};  
				   
				   		var thisobj = this;
			            this.bannerservice.update(banner)
			            .then(bannerdata => {
			              let response = JSON.parse(JSON.stringify(bannerdata));


			              	if (response.status == false) {
		                      this.errors.push(response.message);
		                    }
		                    else {

				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('bannerImage').value!='' && thisobj.dataForm.get('bannerImage').value!=null) {
    									assetPropertyNames+='assetPropertyNames=bannerImage&';
    									formData.append('files', thisobj.dataForm.get('bannerImage').value);
    								}
							

		            let bannerattr ='assetId=' + this.assetId + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {
				    	this.bannerserviceFileupload.add(bannerattr ,formData ).then(model =>{
	    	                if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/banner']);
						          	
						        }
						    }
	                	});
	                }
               else {
              		if (response.status==true) {
						this.router.navigate(['/banner']);
					}
              }
              
            }
        });

  }

  goBack(): void {
    this.location.back();
  }
}
