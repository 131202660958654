import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {PrintingDesignserviceFileupload} from "../services/printingDesignserviceFileupload.service";
import {PrintingDesignservice} from "../services/printingDesignservice.service";
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalStorageService } from 'angular-web-storage';




@Component({
  selector: 'printingDesignAdd',
  templateUrl: './printingDesignAdd.component.html',
  styleUrls: ['../app.component.css']
})
export class PrintingDesignAdd implements OnInit {
  

  dataForm: FormGroup;
  haserror = false;
  errors: string[];
      submittedDataForm = false;

  public Editor = ClassicEditor;
  public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  

  constructor(private fb: FormBuilder, 
    private printingDesignserviceFileupload: PrintingDesignserviceFileupload,
    private printingDesignservice: PrintingDesignservice,
    private location: Location,

    private router: Router,private local: LocalStorageService) { }

  ngOnInit() {

    this.dataForm = this.fb.group({
      designName: [,Validators.required], designImage: [,Validators.required], designImageAlt: [], status: [,Validators.required],
    });

  } 
  goBack(): void {
    this.location.back();
  }
   


  onSelecteddesignImage(event) {
							    if (event.target.files.length> 0) {
							      const designImage = event.target.files[0];
							      this.dataForm.get('designImage').setValue(designImage);
							    }
							  }
  get validate() { return this.dataForm.controls; }

  onSubmit() {
    
   this.submittedDataForm = true;

    // stop here if form is invalid
    if (this.dataForm.invalid) {
        return;
    }
    const formData = new FormData();
				    let printingDesign={"assetName":this.dataForm.get('designName').value,"assetParentId":this.local.get('productcode'),"assetPropertyList":[{"assetTypePropertyName":"designName","assetPropertyValue":this.dataForm.get('designName').value},{"assetTypePropertyName":"designImage","assetPropertyValue":"IGNORE"},{"assetTypePropertyName":"designImageAlt","assetPropertyValue":this.dataForm.get('designImageAlt').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value}],"assetTypeId":this.local.get("printingDesign")};  


				   	var thisobj = this;

		            this.printingDesignservice.add(printingDesign)
		            .then(printingDesigndata => {
		              let response = JSON.parse(JSON.stringify(printingDesigndata));


			        if (response.status == false) {
                      this.errors.push(response.message);
                    }
                    else {
				   
				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('designImage').value!='' && thisobj.dataForm.get('designImage').value!=null) {
    									assetPropertyNames+='assetPropertyNames=designImage&';
    									formData.append('files', thisobj.dataForm.get('designImage').value);
    								}
							

		            let printingDesignattr ='assetId=' + response.response + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {

					    this.printingDesignserviceFileupload.add(printingDesignattr ,formData ).then(model =>{
					   
						    if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/printingDesign']);
						          	
						        }
						    }
					    });
					}
                }
            });
    

  }

}