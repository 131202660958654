import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {SiteContentserviceFileupload} from "../services/siteContentserviceFileupload.service";
import {SiteContentservice} from "../services/siteContentservice.service";
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalStorageService } from 'angular-web-storage';




@Component({
  selector: 'siteContentAdd',
  templateUrl: './siteContentAdd.component.html',
  styleUrls: ['../app.component.css']
})
export class SiteContentAdd implements OnInit {
  

  dataForm: FormGroup;
  haserror = false;
  errors: string[];
      submittedDataForm = false;

  public Editor = ClassicEditor;
  public editorConfig = {
        fontFamily: {
          options: [
            'default',
            'Ubuntu, Arial, sans-serif',
            'Ubuntu Mono, Courier New, Courier, monospace'
          ]
        },
        toolbar: [
          'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' 
        ]
   };


  

  constructor(private fb: FormBuilder, 
    private siteContentserviceFileupload: SiteContentserviceFileupload,
    private siteContentservice: SiteContentservice,
    private location: Location,

    private router: Router,private local: LocalStorageService) { }

  ngOnInit() {

    this.dataForm = this.fb.group({
      logo: [,Validators.required], appVersion: [,Validators.required], paperPrice: [,Validators.required], specialPaperPrice: [,Validators.required], pointPercentage: [,Validators.required], forceUpdate: [,Validators.required], status: [1],
    });

  } 
  goBack(): void {
    this.location.back();
  }
   


  onSelectedlogo(event) {
							    if (event.target.files.length> 0) {
							      const logo = event.target.files[0];
							      this.dataForm.get('logo').setValue(logo);
							    }
							  }
  get validate() { return this.dataForm.controls; }

  onSubmit() {
    
   this.submittedDataForm = true;

    // stop here if form is invalid
    if (this.dataForm.invalid) {
        return;
    }
    const formData = new FormData();
				    let siteContent={"assetName":this.dataForm.get('appVersion').value,"assetParentId":0,"assetPropertyList":[{"assetTypePropertyName":"logo","assetPropertyValue":"IGNORE"},{"assetTypePropertyName":"appVersion","assetPropertyValue":this.dataForm.get('appVersion').value},{"assetTypePropertyName":"paperPrice","assetPropertyValue":this.dataForm.get('paperPrice').value},{"assetTypePropertyName":"specialPaperPrice","assetPropertyValue":this.dataForm.get('specialPaperPrice').value},{"assetTypePropertyName":"pointPercentage","assetPropertyValue":this.dataForm.get('pointPercentage').value},{"assetTypePropertyName":"forceUpdate","assetPropertyValue":this.dataForm.get('forceUpdate').value},{"assetTypePropertyName":"status","assetPropertyValue":this.dataForm.get('status').value}],"assetTypeId":this.local.get("siteContent")};  


				   	var thisobj = this;

		            this.siteContentservice.add(siteContent)
		            .then(siteContentdata => {
		              let response = JSON.parse(JSON.stringify(siteContentdata));


			        if (response.status == false) {
                      this.errors.push(response.message);
                    }
                    else {
				   
				    	let assetPropertyNames = '';

				    


    								if (thisobj.dataForm.get('logo').value!='' && thisobj.dataForm.get('logo').value!=null) {
    									assetPropertyNames+='assetPropertyNames=logo&';
    									formData.append('files', thisobj.dataForm.get('logo').value);
    								}
							

		            let siteContentattr ='assetId=' + response.response + '&' + assetPropertyNames;

		            if (assetPropertyNames!='') {

					    this.siteContentserviceFileupload.add(siteContentattr ,formData ).then(model =>{
					   
						    if (model!=undefined) {	
		    	                let response = JSON.parse(JSON.stringify(model));

						        if (response.status==true) {
							    		 this.router.navigate(['/siteContent']);
						          	
						        }
						    }
					    });
					}
                }
            });
    

  }

}